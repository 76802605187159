<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <invoice-form :invoice="invoice" :own="own" :accessible="accessible">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </invoice-form>
                </div>
            </div>
        </div>
        <div v-if="true === false" class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('invoices::invoices.relationships.items').ucFirst() }}</h4>
                </div>
                <div class="card-body bg-light">
                    <div class="row" v-if="invoice.id">
                        <div class="col-lg-12">
                            <simple-table :items="items" :fields="fields">
                                <template #actions="{item, edit}">
                                    <span class="text-warning cursor-pointer p-2" :title="$t('base.edit').ucFirst()" @click="editTableItem(item, edit)">
                                        <i class="fas fa-edit"></i>
                                    </span>
                                                        <span class="text-danger cursor-pointer p-2" :title="$t('base.delete').ucFirst()" @click="removeTableItem(item)">
                                        <i class="fas fa-trash-alt"></i>
                                    </span>
                                </template>
                                <template v-for="(field, index) in fields" :key="index" v-slot:[`foot_${field.key}`]>
                                    {{ summary[field.key] }}
                                </template>
                                <template #default="{item, close}">
                                    <item-form :item="item" :invoice-id="invoice.id">
                                        <template #buttons>
                                            <b-button class="ms-2" variant="outline-secondary" @click="close">{{ $t('base.cancel').ucFirst() }}</b-button>
                                        </template>
                                    </item-form>
                                </template>
                            </simple-table>
                            <transition-toggle>
                                <item-form class="border rounded p-3 bg-white" v-if="showForm" :redirect="false" :invoice-id="invoice.id">
                                    <template #buttons>
                                        <b-button class="ms-2" variant="secondary" @click="showForm = !showForm">{{ $t('base.cancel').ucFirst() }}</b-button>
                                    </template>
                                </item-form>
                            </transition-toggle>
                            <transition-toggle>
                                <div class="col-12 mb-2 text-center" v-if="!showForm">
                                    <b-button variant="success" @click="showForm = !showForm">{{ $t('base.add_new_item', {item: $tc('projects::offers.relationships.billables', 1)}).ucFirst() }}</b-button>
                                </div>
                            </transition-toggle>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import CallbackButton from "../../../components/elements/callback-button.vue";
import InvoiceForm from "../../../components/forms/invoice-form.vue";
import SimpleTable from "../../../components/elements/simple-table.vue";
import ItemForm from "../../../components/forms/item-form.vue";
import Swal from "sweetalert2";
import TransitionToggle from "../../../components/transitions/toggle.vue";

export default {
    props: {
        own: {
            type: Boolean,
            default: false
        },
        accessible: {
            type: Boolean,
            default: false
        }
    },

    components: {TransitionToggle, ItemForm, SimpleTable, InvoiceForm, CallbackButton},

    computed: {
        ...mapGetters({
            invoice: 'invoice/item',
            items: 'item/all',
            summary: 'item/summary'
        }),

        title: function () {
            return this.invoice.id ? this.$tc('invoices::invoices.invoice', 2).ucFirst() + (this.invoice?.code ? ' / ' : '') + (this.invoice?.code ?? '') : this.$t('base.create_new_item', {item: this.$tc('invoices::invoices.invoice', 1)}).ucFirst()
        },

        link: function () {
            const path = this.own ? '/invoices/my-invoices' : ( this.accessible ? '/invoices/accessible-invoices' : '/invoices');
            return {path: this.$t('routes.' + path)}
        },

        fields: function () {
            return [
                {
                    key: 'billable',
                    label: this.$t('invoices::items.relationships.billable').ucFirst(),
                    formatter: (value) => value?.translation_key ? this.$t(value.translation_key) : value?.name
                },
                {
                    key: 'net_price',
                    label: this.$t('invoices::prices.types.net').ucFirst(),
                    formatter: (value, index, item) => value || item.price?.net,
                    thClass: 'text-center min-width',
                    tdClass: 'text-end'
                },
                {
                    key: 'vat_price',
                    label: this.$t('invoices::prices.types.vat').ucFirst(),
                    formatter: (value, index, item) => value || item.price?.vat,
                    thClass: 'text-center min-width',
                    tdClass: 'text-end'
                },
                {
                    key: 'gross_price',
                    label: this.$t('invoices::prices.types.gross').ucFirst(),
                    formatter: (value, index, item) => value || item.price?.gross,
                    thClass: 'text-center min-width',
                    tdClass: 'text-end'
                },
                {
                    key: 'quantity',
                    label: this.$t('invoices::items.columns.quantity').ucFirst(),
                    thClass: 'text-center min-width',
                    tdClass: 'text-center'
                },
                {
                    key: 'price_label',
                    label: this.$t('invoices::prices.types.amount').ucFirst(),
                    formatter: (value, index, item) => value || item.price?.label,
                    thClass: 'text-center min-width bg-light',
                    tdClass: 'text-end bg-light'
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: 'min-width',
                    tdClass: 'text-end'
                }
            ];
        },
    },

    data: function () {
        return {
            showForm: false
        }
    },

    methods: {
        back: function () {
            this.$router.go(-1);
            // this.$router.push(this.link)
        },

        editTableItem: function (item, callback) {
            this.$store.dispatch(`item/setItem`, item).then(() => {
                this.$store.dispatch(`item/summary`)
                callback(item)
            })
        },

        removeTableItem: function (item) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch(`item/destroy`, item.id).then(() => {
                        this.$store.dispatch(`item/summary`)
                    })
                }
            });
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('invoice/show', {id: this.$route.params.id, query: {with: ['items', 'target', 'type']}})
            this.$store.dispatch('item/all', {invoice_id: this.$route.params.id, with: ['price', 'unit', 'billable']})
        }
    },

    unmounted() {
        this.$store.dispatch('invoice/clearItem')
        this.$store.dispatch('item/clearAll')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
