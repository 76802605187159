<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";

export default defineComponent({
    name: "item-form",

    emits: ['saved', 'updated'],

    props: {
        item: {
            type: Object,
            required: true
        },

        fixed: {
            type: Boolean,
            default: false
        },

        own: {
            type: Boolean,
            default: false
        },

        redirect: {
            type: Boolean,
            default: true
        },

        doNotSave: {
            type: Boolean,
            default: false
        },

        accessible: {
            type: Boolean,
            default: false
        },

        invoiceId: {
            type: Number
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'item/loading',
            'states': 'item/states',
            'errors': 'item/errors',
            billables: 'billable/all',
            units: 'unit/all',
            prices: 'price/all',
            // item: 'item/item',
            products: 'billable/products',
            services: 'billable/services',
            currencies: 'currency/all'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        }
    },

    data: function () {
        return {
            model: {},
            billable: {}
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({query: {with: ['billable']}}, this.model)

            if (!this.doNotSave) {
                return this.$store.dispatch(`item/${method}`, data).then(saved => {
                    this.setModel(saved)
                    this.$emit('saved')
                    if (method === 'create' && this.redirect) {
                        const pathType = this.own ? '/items/my-items' : (this.accessible ? '/items/accessible-items' : '/items')
                        this.$router.push(this.$t('routes.'+ pathType + '/:id(\\d+)', {id: this.model.id}).rmValidation())
                    }
                })
            } else {
                this.$store.dispatch('item/setItem', this.model)
                this.$emit('updated', this.model)
            }
        },

        fetchBillables: function () {
            this.$store.dispatch('billable/all').then(() => {
                this.billable = this.billables.find(item => item.type === this.model.billable_type && item.id === this.model.billable_id)
            })
        },

        fetchUnits: function () {
            this.$store.dispatch('unit/all')
        },

        fetchCurrencies: function () {
            this.$store.dispatch('currency/all')
        },

        fetchPrices: function () {
            this.$store.dispatch('price/all', {billable_type: this.model.billable_type, billable_id: this.model.billable_id, is_active: true, with: ['currency']})
        },

        setModel: function (model = {}) {
            const base = {}

            if (this.invoiceId) {
                base.invoice_id = this.invoiceId
            }

            this.model = Object.assign(base, JSON.parse(JSON.stringify(model)))
        },

        setFile: function (event, prop = 'file_file') {
            this.model[prop] = event.target.files[0]
        },

        setDetails: function () {
            this.setModel(Object.assign({}, this.model, {
                billable_type: this.billable.type,
                billable_id: this.billable.id
            }))
            this.fetchPrices()
        },
    },

    watch: {
        item: {
            immediate: true,
            deep: true,
            handler: function (value) {
                this.setModel(value)
            },
        },

        invoiceId: {
            immediate: true,
            handler: function (id) {
                if (id) {
                    this.setModel(this.model)
                }
            },
        },

        billable: {
            deep: true,
            handler: function () {
                this.fetchPrices()
            },
        }
    },

    created() {
        this.setModel(this.item)
        this.fetchBillables()
        this.fetchUnits()
        this.fetchPrices()
        this.fetchCurrencies()
    },

    unmounted() {
        this.$store.dispatch('implement/clearAll')
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.billable_id === false}"
                        class="form-label"
                        for="billable_id-input"
                    >{{ $t('invoices::items.columns.billable_id').ucFirst() }}</label
                    >
                    <select
                        id="billable_id-input"
                        v-model="billable"
                        :class="{'border-danger': states.billable_id === false}"
                        class="form-select"
                        name="billable_id"
                        @change="setDetails"
                    >
                        <option :value="billable === undefined ? undefined : null">
                            {{ $t('invoices::items.placeholders.billable_id').ucFirst() }}
                        </option>
                        <optgroup :label="$tc('projects::products.product', 2).ucFirst()">
                            <option v-for="(item, index) in products" :key="index" :value="item">
                                {{ item.translated }}
                            </option>
                        </optgroup>
                        <optgroup :label="$tc('projects::services.service', 2).ucFirst()">
                            <option v-for="(item, index) in services" :key="index" :value="item">
                                {{ item.translated }}
                            </option>
                        </optgroup>
                    </select>
                    <div :class="{'d-block': states.billable_id === false}" class="invalid-feedback">{{ errors.billable_id }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.quantity === false}"
                        class="form-label"
                        for="quantity"
                    >{{ $t('invoices::items.columns.quantity').ucFirst() }}</label
                    >

                    <input
                        id="quantity"
                        v-model="model.quantity"
                        :class="{'border-danger': states.quantity === false}"
                        :placeholder="$t('invoices::items.placeholders.quantity').ucFirst()"
                        class="form-control"
                        type="number"
                    />
                    <div :class="{'d-block': states.quantity === false}" class="invalid-feedback">{{ errors.quantity }}</div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.unit_id === false}"
                        class="form-label"
                        for="unit_id"
                    >{{ $t('invoices::items.columns.unit_id').ucFirst() }}</label>
                    <select
                        id="unit_id"
                        v-model="model.unit_id"
                        :class="{'border-danger': states.unit_id === false}"
                        class="form-select"
                        name="unit_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('invoices::items.placeholders.unit_id').ucFirst() }}
                        </option>
                        <option v-for="(item, key) in units" :key="key" :value="item.id">{{
                                item.translation_key ? $t(item.translation_key) : item.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.unit_id === false}" class="invalid-feedback">
                        {{ errors.unit_id }}
                    </div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.price_id === false}"
                        class="form-label"
                        for="price_id"
                    >{{ $t('invoices::items.columns.price_id').ucFirst() }}</label>
                    <select
                        id="price_id"
                        v-model="model.price_id"
                        :class="{'border-danger': states.price_id === false}"
                        class="form-select"
                        name="price_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('invoices::items.placeholders.price_id').ucFirst() }}
                        </option>
                        <option v-for="(item, key) in prices" :key="key" :value="item.id">{{
                                item.label
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.price_id === false}" class="invalid-feedback">
                        {{ errors.price_id }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.custom_price === false}"
                        class="form-label"
                        for="custom_price"
                    >{{ $t('invoices::items.columns.custom_price').ucFirst() }}</label
                    >

                    <input
                        id="custom_price"
                        v-model="model.custom_price"
                        :class="{'border-danger': states.custom_price === false}"
                        :placeholder="$t('invoices::items.placeholders.custom_price').ucFirst()"
                        class="form-control"
                        type="number"
                        step="0.01"
                    />
                    <div :class="{'d-block': states.custom_price === false}" class="invalid-feedback">{{ errors.custom_price }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.custom_vat === false}"
                        class="form-label"
                        for="custom_vat"
                    >{{ $t('invoices::items.columns.custom_vat').ucFirst() }}</label
                    >

                    <input
                        id="custom_vat"
                        v-model="model.custom_vat"
                        :class="{'border-danger': states.custom_vat === false}"
                        :placeholder="$t('invoices::items.placeholders.custom_vat').ucFirst()"
                        class="form-control"
                        type="number"
                        step="0.01"
                    />
                    <div :class="{'d-block': states.custom_vat === false}" class="invalid-feedback">{{ errors.custom_vat }}</div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.custom_currency === false}"
                        class="form-label"
                        for="custom_currency"
                    >{{ $t('invoices::items.columns.custom_currency').ucFirst() }}</label>
                    <select
                        id="custom_currency"
                        v-model="model.custom_currency"
                        :class="{'border-danger': states.custom_currency === false}"
                        class="form-select"
                        name="custom_currency"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('invoices::items.placeholders.custom_currency').ucFirst() }}
                        </option>
                        <option v-for="(item, key) in currencies" :key="key" :value="item.code">{{
                                (item.translation_key ? $t(item.translation_key).ucFirst() : item.name) + ' (' + item.symbol + ')'
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.custom_currency === false}" class="invalid-feedback">
                        {{ errors.custom_currency }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-12 text-center">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
